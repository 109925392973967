import { cn } from '~/utils/cn';

export function PrimaryButton({
    children,
    className,
}: React.PropsWithChildren<{
    className?: string;
}>) {
    return (
        <button
            type="button"
            className={cn(
                'group button relative mr-2 inline-flex items-center justify-center overflow-hidden rounded-lg bg-gradient-to-br from-green-400 to-blue-600 p-0.5 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-green-800 group-hover:from-green-400 group-hover:to-blue-600',
                className,
            )}
        >
            <span className="relative rounded-md bg-gray-900 px-5 py-2.5 transition-all duration-150 ease-in group-hover:bg-opacity-0">
                {children}
            </span>
        </button>
    );
}
